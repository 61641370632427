<template>
  <div class="file-wrapper">
    <div :class="{'file-doc': file}" class="upload">
      <div v-if="file">
        <span class="name" v-if="docType === 'application/pdf' || docType === 'application/msword' || docType === 'text/plain' || docType === 'text/csv'">
          <img src="@/assets/icons/pdf.svg" v-if="docType === 'application/pdf'">
          <img src="@/assets/icons/csv.png" v-else-if="docType === 'text/csv'">
          <img src="@/assets/icons/document.svg" v-else>
          <span>{{ file }}</span>
        </span>
        <img :src="file" class="image" v-else>
        <span class="close pointer" @click="close">
          <img src="@/assets/icons/close_icon.png">
        </span>
      </div>
      <div v-else>
        <p>Upload a file from your computer</p>
        <span class="pointer" @click="uploadFile">
          <input type="file" id="files" :accept="type === 'csv' ? '.csv' : ''" @change="chooseFile"/>
          <img src="@/assets/icons/upload.svg">
        </span>
      </div>
    </div>
    <div class="download">
      <p>You can {{ type === 'csv' ? 'import' : 'download' }} document.</p>
      <span>
        <img src="@/assets/icons/csv.png" v-if="type === 'csv'">
        <img src="@/assets/icons/pdf.svg" v-else>
      </span>
      <button class="pointer" @click.prevent="$emit('importFile', { fileDetails : updateFile })" v-if="type === 'csv'">Import</button>
      <button class="pointer" @click.prevent="downloadFile" v-else>Download</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: null,
      docType: null,
      updateFile: null
    }
  },
  watch: {
    updateFile: function () {
      this.$emit('uploadFile', this.updateFile)
    }
  },
  methods: {
    downloadFile() {
      const url = '/Credit_Application.pdf'
      axios({
        url: url,
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Credit Application.pdf'
        link.click()
      })
    },
    uploadFile() {
      const file = document.getElementById('files')
      file.click()
    },
    chooseFile(event) {
      this.docType = event.target.files[0].type
      if (this.docType === 'application/pdf' || this.docType === 'application/msword' || this.docType === 'text/plain' || this.docType === 'text/csv') {
        this.file = event.target.files[0].name
      } else {
        this.file = window.URL.createObjectURL(event.target.files[0])
      }
      this.updateFile = event.target.files[0]
    },
    close() {
      this.file = null
      this.updateFile = null
    }
  }
}
</script>

<style lang="sass" scoped>
.file-wrapper
  display: flex
  justify-content: space-between
  @media screen and (max-width: $md-breakpoint)
    flex-direction: column
  .upload, .download
    width: 45%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media screen and (max-width: $md-breakpoint)
      width: 100%
    p
      margin-bottom: 1em
      @include font-source('Poppins', 16px, 400, $primary-text)
  .upload
    border: 1px solid
    border-style: dashed
    @media screen and (max-width: $md-breakpoint)
      padding: 25px 0
      margin-bottom: 1em
    input[type="file"]
      display: none
    &.file-doc
      position: relative
      .image
        max-width: 100%
      &:hover
        .close
          display: flex
      .close
        display: none
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 0
        margin: 0 auto
        background: rgba(0,0,0,0.4)
        justify-content: center
        align-items: center
      .name
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
    span
      img
        width: 50px
  .download
    background: $grey-2
    padding-top: 25px
    span
      margin-bottom: 1em
      img
        width: 100px
    button
      width: 100%
      height: 50px
      background: $orange
      border: none
      color: $white
      @include font-source('Poppins', 13px, 600)
</style>
